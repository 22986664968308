import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { PassportService } from '@auth/passport.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DevicesApiService } from '@core/services/api/devices-api.service';
import { NektaTableColumn, NektaTablePagination } from '@shared/components/nekta-table/nekta-table.component';
import { IdTitleSelector, IdValueSelector } from '@core/models/ui.models';
import { DEVICE_ADMIN_POLL_KEYS, DevicePollModalComponent } from '@shared/components/device-poll-modal/device-poll-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { sortByKey } from '@core/helpers';

const ADMIN_TABLE_COLUMNS = [
    'device_id',
    'broker_port',
    'ip_address',
    'last_date',
    'connect_status',
    'modem_type',
    'end_device_type',
    'end_device_version',
    'rssi',
    'poll'
] as const;

type AdminColumnKey = (typeof ADMIN_TABLE_COLUMNS)[number];

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.less']
})
export class AdminComponent implements OnInit, OnDestroy {
    isSpinnerVisible = true;
    isAllInitialDataLoaded = false;
    isAuth = true;
    searchString = null;

    rawDevices = [];
    devices = [];

    rawPorts = [];
    pollFieldTitles = [];

    tableColumns: NektaTableColumn<AdminColumnKey>[] = [];
    customCellColumns: IdValueSelector<AdminColumnKey, TemplateRef<any>>[] = [];
    sortColumns: AdminColumnKey[] = ['device_id', 'last_date'];

    displayedColumns: string[] = ADMIN_TABLE_COLUMNS.slice(0);

    portFilterOptions: IdTitleSelector[] = [];
    statusFilterOptions: IdTitleSelector[] = [];

    readonly pageSizeOptions: number[] = [10, 25, 100, 250, 500, 1000];
    defaultSort: Sort = {
        active: 'last_date',
        direction: 'desc'
    };
    sort: Sort = { ...this.defaultSort };
    readonly defaultFilter: any = {
        broker_port: undefined,
        connect_status: undefined
    } as const;
    filter = { ...this.defaultFilter };
    pagination: NektaTablePagination = {
        pageSizeOptions: this.pageSizeOptions
    };

    @ViewChild('brokerPortTd', { static: true }) brokerPortTd: TemplateRef<any>;
    @ViewChild('lastDateTd', { static: true }) lastDateTd: TemplateRef<any>;
    @ViewChild('statusTd', { static: true }) statusTd: TemplateRef<any>;
    @ViewChild('pollTd', { static: true }) pollTd: TemplateRef<any>;

    private readonly filter$: BehaviorSubject<any> = new BehaviorSubject(this.filter);
    private readonly search$: BehaviorSubject<string> = new BehaviorSubject(this.searchString);
    private readonly sorter$: BehaviorSubject<Sort> = new BehaviorSubject(this.sort);
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private readonly auth: PassportService,
        private readonly devicesApiService: DevicesApiService,
        private readonly matDialog: MatDialog,
        private readonly router: Router,
        private readonly translate: TranslateService
    ) {}

    ngOnInit() {
        this.customCellColumns = [
            { id: 'broker_port', value: this.brokerPortTd },
            { id: 'last_date', value: this.lastDateTd },
            { id: 'connect_status', value: this.statusTd },
            { id: 'poll', value: this.pollTd }
        ];
        this.auth.getSiteProperties().subscribe((res: any) => {
            this.isAuth =
                !!res?.data?.is_kerberos ||
                (localStorage.getItem('access_token') !== 'null' &&
                    localStorage.getItem('access_token') !== undefined &&
                    localStorage.getItem('access_token') !== null);
            if (this.isAuth) {
                this.setInitialData();
            } else {
                this.isSpinnerVisible = false;
            }
        });

        combineLatest([this.filter$, this.search$, this.sorter$])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.updateDevices());
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
        this.filter$.complete();
        this.search$.complete();
        this.sorter$.complete();
    }

    onChangeFilteringColumn(event: IdValueSelector | null) {
        if (!event) {
            this.filter = { ...this.defaultFilter };
        } else {
            this.filter[event.id] = event.value;
        }
        this.filter$.next(this.filter);
    }

    onSearch(event: string | null): void {
        this.searchString = event;
        this.search$.next(this.searchString);
        this.setFirstPage();
    }

    onSort(event: Sort | null) {
        const direction = this.sort.direction;
        this.sort = event ? event : { ...this.defaultSort };
        if (direction === this.sort.direction) {
            this.sort.direction = direction === 'asc' ? 'desc' : 'asc';
        }
        this.sorter$.next(this.sort);
        this.setFirstPage();
    }

    onLogin() {
        this.isAuth = true;
        this.setInitialData();
    }

    clearFilters() {
        this.searchString = null;
        this.filter = { ...this.defaultFilter };
        this.sort = { ...this.defaultSort };
        this.filter$.next(this.filter);
        this.search$.next(this.searchString);
        this.sorter$.next(this.sort);
    }

    getPortName(v) {
        return this.portFilterOptions.find((w) => w.id === v?.broker_port)?.title || '-';
    }

    openPollModal(device) {
        const item = { device, pollFieldTitles: this.pollFieldTitles };
        this.matDialog.open(DevicePollModalComponent, { data: item, disableClose: true });
    }

    getDevices() {
        this.clearFilters();
        this.isSpinnerVisible = true;
        this.devicesApiService.getConnectedDevicesForAdmin({}).subscribe(
            (res) => {
                this.rawDevices = res?.data || [];
                this.pagination.length = this.rawDevices.length;
                this.devices = this.rawDevices.slice(0);
                this.setPortFilterOptions();
                this.isSpinnerVisible = false;
                this.updateDevices();
            },
            (error) => {
                if ([401, 403].includes(error.status)) {
                    this.isAuth = false;
                    this.router.navigate(['withoutAccess']).then();
                }
                this.isSpinnerVisible = false;
            }
        );
    }

    private setFirstPage() {
        this.pagination.pageIndex = 0;
    }

    private setInitialData() {
        this.devicesApiService.getDeviceFieldTitles().subscribe(
            (res: any) => {
                this.pollFieldTitles = (res?.data?.fields || [])
                    .filter((w) => DEVICE_ADMIN_POLL_KEYS.includes(w.name))
                    .map((z) => ({
                        id: z.name,
                        title: this.translate.currentLang === 'en' ? z?.title_en : z?.title,
                        unit: this.translate.currentLang === 'en' ? z?.unit?.title_en : z?.unit?.title
                    }));
            },
            (error) => {
                if ([401, 403].includes(error.status)) {
                    this.isAuth = false;
                    this.router.navigate(['withoutAccess']).then();
                }
            }
        );
        this.devicesApiService.getDevicesPortsForAdmin().subscribe(
            (ports: any) => {
                this.isAuth = true;
                this.rawPorts = Object.entries(ports?.data || {}).map(([title, id]) => ({ title, id }));
                this.statusFilterOptions = [
                    { id: 'online', title: this.translate.instant('common.online') },
                    { id: 'offline', title: this.translate.instant('common.offline') }
                ];
                this.fillTableColumns();
                this.getDevices();
                this.isAllInitialDataLoaded = true;
            },
            (error) => {
                if ([401, 403].includes(error.status)) {
                    this.isAuth = false;
                    this.router.navigate(['withoutAccess']).then();
                }
            }
        );
    }

    private setPortFilterOptions() {
        this.portFilterOptions = this.rawDevices.length ? this.getFilteredPortsByDevices() : this.rawPorts.slice(0);
        if (this.tableColumns.length) {
            this.tableColumns[1].filterOptions = this.portFilterOptions;
        }
    }

    private getFilteredPortsByDevices() {
        const devicesPorts = [...new Set(this.rawDevices.map((w) => w?.broker_port))];
        return this.rawPorts.filter((w) => devicesPorts.includes(w.id));
    }

    private fillTableColumns() {
        ADMIN_TABLE_COLUMNS.forEach((column) => {
            this.tableColumns.push({
                id: column,
                header: this.getHeaderName(column),
                filterOptions: this.getFilterOptions(column),
                tdRef: this.getTdRefOptions(column),
                sort: this.sortColumns.includes(column)
            });
        });
    }

    private getFilterOptions(column: AdminColumnKey): IdTitleSelector[] | undefined {
        if (column === 'broker_port') {
            return this.portFilterOptions;
        }
        if (column === 'connect_status') {
            return this.statusFilterOptions;
        }
        return undefined;
    }

    private getHeaderName(column: AdminColumnKey) {
        return this.translate.instant('admin.columns.' + column);
    }

    private getTdRefOptions(column: AdminColumnKey): TemplateRef<any> | undefined {
        return this.customCellColumns.find((w) => w.id === column)?.value;
    }

    private updateDevices() {
        this.devices = [];
        this.devices = !!this.searchString
            ? this.rawDevices.filter((w) => typeof w.device_id === 'string' && w.device_id.toLowerCase().includes(this.searchString.trim().toLowerCase()))
            : this.rawDevices.slice(0);
        if (this.filter.broker_port) {
            this.devices = this.devices.filter((w) => w.broker_port === this.filter.broker_port);
        }
        if (this.filter.connect_status) {
            const statusFilter = this.filter.connect_status === 'online';
            this.devices = this.devices.filter((w) => w.connect_status === statusFilter);
        }
        this.devices = this.devices.sort((a, b) => sortByKey(a, b, this.sort.active, this.sort.direction));
    }
}
