import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDataWithCheckbox } from '@core/services/dialog.service';

@Component({
  selector: 'app-alert-with-checkbox',
  templateUrl: './alert-with-checkbox.component.html',
  styleUrls: ['./alert-with-checkbox.component.less']
})

export class AlertWithCheckboxComponent implements OnInit {
    title = 'common.confirm_action';
    content = '';
    confirmBtn = 'common.yes';
    cancelBtn = 'common.no';
    checkbox = 'common.checkbox';
    isError = false;
    isCheckboxChecked = false;

    constructor(
        private dialogRef: MatDialogRef<AlertWithCheckboxComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) private data: DialogDataWithCheckbox
    ) {}

    ngOnInit() {
        if (this.data) {
            this.title = this.data?.title ?? 'common.confirm_action';
            this.content = this.data?.content ?? '';
            this.confirmBtn = this.data?.confirmBtn ?? 'common.yes';
            this.cancelBtn = this.data?.cancelBtn ?? 'common.no';
            this.checkbox = this.data?.checkbox ?? 'common.checkbox';
            this.isError = !!this.data?.isError;
        }
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onClose(): void {
        this.dialogRef.close(false);
    }

}
