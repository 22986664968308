import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PassportService } from '../../../auth/passport.service';
import * as _ from 'lodash';
import { CheckAccess, DateManipulations } from '../../cloud.service';
import { ErrorsService } from '@core/services/errors.service';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DeviceModelInterfacesEnum } from '@core/enums';

@Injectable({
    providedIn: 'root'
})
export class DevicesService {
    state = 0;
    _baseURL: string;
    public headers = new HttpHeaders();
    token: string;
    selectedTab: any = 0;
    deviceModelInterfacesEnum = DeviceModelInterfacesEnum;
    dateForm: any = null;
    refreshData = new Subject();
    listActiveRoute: string | null = null;
    devicesCurrentTabs = {
        lists: 0,
        groups: 0,
        import: 0
    };

    private _deviceDataCurrentTab$ = new BehaviorSubject<string | null>(null);
    private _deviceDataFormValue$ = new BehaviorSubject<any>(null);

    constructor(
        private httpClient: HttpClient,
        private auth: PassportService,
        public _checkAccess: CheckAccess,
        private errors: ErrorsService,
        public dateManipulations: DateManipulations
    ) {
        this._baseURL = environment.baseURL;
    }

    set tab(tab) {
        this.selectedTab = tab;
    }

    get reportTab() {
        return new Observable((observer) => {
            observer.next(this.selectedTab);
            observer.complete();
        });
    }

    private _devicesHeaderTitle$ = new BehaviorSubject<string | null>(null);

    get devicesHeaderTitle$() {
        return this._devicesHeaderTitle$.asObservable();
    }

    set devicesHeaderTitle(v: string | null) {
        this._devicesHeaderTitle$.next(v);
    }

    deviceDataCurrentTab$() {
        return this._deviceDataCurrentTab$.asObservable();
    }

    setDeviceDataCurrentTab$(v: string | null) {
        this._deviceDataCurrentTab$.next(v);
    }

    deviceDataFormValue$() {
        return this._deviceDataFormValue$.asObservable();
    }

    setDeviceDataFormValue$(v) {
        this._deviceDataFormValue$.next(v);
    }

    getListOfGroup(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/opros/group/list', body, {
            headers: this.headers
        });
    }

    getPort(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/transparent_listener_switch', body, {
            headers: this.headers
        });
    }

    getBrands() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/model/brands', '', {
            headers: this.headers
        });
    }

    getReservedMaxPower(id: string, year) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + `device/messages/reserved_max_power/${id}`, year, {
            headers: this.headers
        });
    }

    setTariff(id, body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(
            this._baseURL + 'device/save_schedule_electro',
            {
                device_id: id,
                data: body
            },
            {
                headers: this.headers
            }
        );
    }

    getOneMeteringDevice(id) {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .get(this._baseURL + 'device/metering_device/' + id, {
                    headers: this.headers
                })
                .pipe(
                    map((data: any) => {
                        return data.data.metering_device;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_18', true);
        }
    }

    getOneGateway(id) {
        if (this._checkAccess.listAccess('read', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.get(this._baseURL + 'device/gateway/' + id, { headers: this.headers }).pipe(
                map((data: any) => {
                    return data.data.gateway;
                })
            );
        } else {
            this.errors.showSnackbar('msg_19', true);
        }
    }

    getOneBaseStation(id) {
        if (this._checkAccess.listAccess('read', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .get(this._baseURL + 'device/base_station/' + id, {
                    headers: this.headers
                })
                .pipe(
                    map((data: any) => {
                        return data.data.base_station;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_20', true);
        }
    }

    getMeteringDevices(
        previousPage,
        pageIndex,
        pageSize,
        fieldSort,
        directionSort,
        search,
        device_group_id?,
        device_state = null,
        filterModel?,
        fields_filter?,
        device_ids?,
        paginate?,
        model_id?,
        available_accounting_points?,
        interfaceId?,
        appendFields?,
        model_options?,
        only = null,
        gateway_ids?
    ) {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            const body = {
                page: pageIndex,
                last_page: previousPage,
                sort_field: fieldSort,
                sort: directionSort,
                search_string: search,
                device_state: device_state,
                is_archived: false
            };
            if (!_.isUndefined(filterModel) && !_.isNull(filterModel)) {
                body['model_id'] = filterModel;
            }
            if (model_options) {
                body['model_options'] = model_options;
            }
            if (!_.isUndefined(interfaceId) && !_.isNull(interfaceId)) {
                body['interface_id'] = interfaceId;
            }
            if (device_group_id !== undefined && device_group_id !== null && device_group_id !== 0) {
                if (Array.isArray(device_group_id)) {
                    body['device_group_id'] = device_group_id.map((groupId) => +groupId);
                } else {
                    body['device_group_id'] = [+device_group_id];
                }
            }
            if (!_.isUndefined(fields_filter) && !_.isNull(fields_filter)) {
                _.set(body, 'fields_filter', fields_filter);
            }
            if (!_.isUndefined(device_ids) && !_.isNull(device_ids) && typeof device_ids !== 'string') {
                body['ids'] = device_ids;
            }
            if (!_.isUndefined(paginate) && !_.isNull(paginate)) {
                body['paginate'] = paginate;
            }
            if (!_.isUndefined(appendFields) && !_.isNull(appendFields)) {
                body['append_fields'] = appendFields;
            }
            if (!_.isUndefined(model_id) && !_.isNull(model_id)) {
                body['model_id'] = model_id;
            }
            if (!_.isUndefined(pageSize) && !_.isNull(pageSize)) {
                body['per_page'] = pageSize;
            }
            if (!_.isUndefined(available_accounting_points) && !_.isNull(available_accounting_points)) {
                body['available_accounting_points'] = available_accounting_points;
            }
            if (!_.isUndefined(gateway_ids) && !_.isNull(gateway_ids)) {
                body['gateway_ids'] = gateway_ids;
            }
            if (only) {
                body['only'] = only;
            }
            return this.httpClient
                .post(this._baseURL + 'device/metering_devices', body, {
                    headers: this.headers
                })
                .pipe(
                    map((data: any) => {
                        return data.data.metering_devices;
                    })
                )
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_21', true);
        }
    }

    getMeteringDevicesFullList(body) {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.post(this._baseURL + 'device/metering_devices', body, { headers: this.headers });
        } else {
            this.errors.showSnackbar('msg_21', true);
        }
    }

    getMeteringDeviceByGroups(deviceGroups) {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(
                    this._baseURL + 'device/metering_devices',
                    { paginate: false, device_group_id: deviceGroups },
                    {
                        headers: this.headers
                    }
                )
                .pipe(
                    map((data: any) => {
                        return data.data.metering_devices;
                    })
                )
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_21', true);
        }
    }

    getDevicesGroups() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'device/model/metering_device/groups', '', {
                headers: this.headers
            })
            .toPromise();
    }

    devices_groups() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/model/metering_device/groups', '', {
            headers: this.headers
        });
    }

    gateway_create(data) {
        if (this._checkAccess.listAccess('create', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(this._baseURL + 'device/gateway/create', data, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_22', true);
        }
    }

    baseStation_create(data) {
        if (this._checkAccess.listAccess('create', 4) || this._checkAccess.listAccess('access_all', 4)) {
            const body = data;
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(this._baseURL + 'device/base_station/create', body, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_23', true);
        }
    }

    device_create(data) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'device/metering_device/create', data, {
                headers: this.headers
            })
            .toPromise();
    }

    getMeteringDeviceModels(company_id = null, device_group_id = null) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const reqData = {};
        if (company_id !== null) {
            reqData['company_id'] = company_id;
        }
        if (device_group_id) {
            reqData['device_group_id'] = device_group_id;
        }
        return this.httpClient.post(this._baseURL + 'device/model/metering_devices', { ...reqData }, { headers: this.headers });
    }

    getGatewaysDevices(paginate, perPage, page, fieldSort, directionSort, searchString, gateway_state = null, filterModel?) {
        if (this._checkAccess.listAccess('read', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            let body;
            if (!_.isNull(perPage) && !_.isNull(page) && !_.isNull(paginate)) {
                body = {
                    page: page,
                    per_page: perPage,
                    paginate: paginate,
                    device_state: gateway_state
                };
                if (!_.isUndefined(fieldSort) && !_.isNull(fieldSort)) {
                    body['sort_field'] = fieldSort;
                }
                if (!_.isUndefined(directionSort) && !_.isNull(directionSort)) {
                    body['sort'] = directionSort;
                }
                if (!_.isUndefined(searchString) && !_.isNull(searchString)) {
                    body['search_string'] = searchString;
                }
                if (!_.isUndefined(filterModel) && !_.isNull(filterModel)) {
                    body['model_id'] = filterModel;
                }
            }
            return this.httpClient
                .post(this._baseURL + 'device/gateways', body, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_24', true);
        }
    }

    getGatewaysFullList(body) {
        if (this._checkAccess.listAccess('read', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.post(this._baseURL + 'device/gateways', body, { headers: this.headers });
        } else {
            this.errors.showSnackbar('msg_24', true);
        }
    }

    getGatewaysByIds(gateway_ids) {
        if (this._checkAccess.listAccess('read', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            let body;
            body = {
                ids: gateway_ids,
                page: 0,
                per_page: 1000
            };
            return this.httpClient
                .post(this._baseURL + 'device/gateways', body, {
                    headers: this.headers
                })
                .pipe(
                    map((data: any) => {
                        return data.data.gateways.data;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_24', true);
        }
    }

    getGatewaysModels(company_id = null) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/model/gateways', company_id !== null ? { company_id: company_id } : '', {
            headers: this.headers
        });
    }

    getBaseStationDevices(
        paginate = null,
        base_station_model_id = null,
        previousPage?,
        pageIndex?,
        pageSize?,
        fieldSort?,
        directionSort?,
        search?,
        is_deleted?
    ) {
        if (this._checkAccess.listAccess('read', 4) || this._checkAccess.listAccess('access_all', 4)) {
            let body = {};
            if (!paginate) {
                body = {
                    paginate: paginate,
                    par_page: 1000
                };
            }
            if (!_.isNull(base_station_model_id)) {
                body['base_station_model_id'] = base_station_model_id;
            }
            if (!paginate && !base_station_model_id) {
                body = '';
            }

            if (paginate) {
                body['page'] = pageIndex;
                body['last_page'] = previousPage;
                body['per_page'] = pageSize;
                body['sort_field'] = fieldSort;
                body['sort'] = directionSort;
                body['search_string'] = search;
                body['is_deleted'] = is_deleted;
            }

            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(this._baseURL + 'device/base_stations', body, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_25', true);
        }
    }

    getBaseStationModels(company_id = null) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/model/base_stations', company_id !== null ? { company_id: company_id } : '', {
            headers: this.headers
        });
    }

    getMessages(
        id,
        msgType,
        startDate,
        stopDate,
        msgGroup,
        per_page,
        tab?,
        is_energy = false,
        profile_type?,
        type = 'device',
        with_transformation_ratio = true,
        with_loss_factor = true,
        RAMode = false,
        extra_fields = false
    ) {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            const body = {
                device_id: id,
                msgType: msgType,
                startDate: startDate,
                msgGroup: msgGroup,
                is_energy: is_energy,
                paginate: false,
                stopDate: stopDate,
                per_page: per_page,
                with_transformation_ratio,
                with_loss_factor,
                'r/a': RAMode
            };
            if (tab !== undefined && tab !== null) {
                body['tab'] = tab;
            }
            if (!_.isUndefined(profile_type) && !_.isNull(profile_type)) {
                body['profile_type'] = profile_type;
            }
            if (extra_fields) {
                body['extra_fields'] = {
                    reason: 'reason',
                    method: 'method',
                    message_api: 'message_api'
                };
            }

            if (type === 'device') {
                return this.httpClient
                    .post(this._baseURL + 'device/messages', body, {
                        headers: this.headers
                    })
                    .pipe(
                        map((data: any) => {
                            return data.data.messages;
                        })
                    );
            } else if (type === 'accounting_point') {
                delete body['device_id'];
                body['accounting_point_id'] = id;
                return this.httpClient
                    .post(this._baseURL + 'objects/accounting_point/messages', body, {
                        headers: this.headers
                    })
                    .pipe(
                        map((data: any) => {
                            return data.data.messages;
                        })
                    );
            }
        } else {
            this.errors.showSnackbar('msg_26', true);
        }
    }

    //TODO: delete unused functions (method was copied form "getMessages")
    getEnergyMessages(
        id,
        msgType,
        startDate,
        stopDate,
        msgGroup,
        per_page,
        tab?,
        is_energy = false,
        profile_type?,
        type = 'device',
        with_transformation_ratio = true,
        with_loss_factor = true,
        RAMode = false,
        extra_fields = false
    ) {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            const body = {
                device_id: id,
                msgType: msgType,
                startDate: startDate,
                msgGroup: msgGroup,
                is_energy: is_energy,
                paginate: false,
                stopDate: stopDate,
                per_page: per_page,
                with_transformation_ratio,
                with_loss_factor,
                'r/a': RAMode
            };
            if (tab !== undefined && tab !== null) {
                body['tab'] = tab;
            }
            if (!_.isUndefined(profile_type) && !_.isNull(profile_type)) {
                body['profile_type'] = profile_type;
            }
            if (extra_fields) {
                body['extra_fields'] = {
                    reason: 'reason',
                    method: 'method',
                    message_api: 'message_api'
                };
            }
            if (type === 'device') {
                return this.httpClient
                    .post(this._baseURL + 'device/energy_profile', body, { headers: this.headers })
                    .pipe(map((data: any) => data.data.messages));
            }
        } else {
            this.errors.showSnackbar('msg_26', true);
        }
    }

    getPowerProfilesByIds(id, msgType, startDate, stopDate, per_page, tab?, profile_type?, with_transformation_ratio = true) {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            const body = {
                device_id: id,
                msgType: msgType,
                msgGroup: 0,
                tab: 'power_profiles',
                startDate: startDate,
                paginate: false,
                stopDate: stopDate,
                per_page: per_page,
                with_transformation_ratio
            };
            if (!_.isUndefined(profile_type) && !_.isNull(profile_type)) {
                body['profile_type'] = profile_type;
            }
            return this.httpClient
                .post(this._baseURL + 'device/messages', body, {
                    headers: this.headers
                })
                .pipe(
                    map((data: any) => {
                        return data.data.messages;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_26', true);
        }
    }

    getBaseStationMessages(id, msgType, startDate, stopDate, page, per_page) {
        if (this._checkAccess.listAccess('read', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            const body = {
                device_id: id,
                msgType: msgType,
                startDate: startDate,
                msgGroup: 0,
                paginate: true,
                stopDate: stopDate,
                page: page,
                per_page: per_page
            };
            return this.httpClient
                .post(this._baseURL + 'base_station/messages', body, {
                    headers: this.headers
                })
                .pipe(
                    map((data: any) => {
                        return data.data.messages;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_27', true);
        }
    }

    getPowerProfile(id, startDate, stopDate, per_page, profile_type?) {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            const body = {
                device_id: id,
                startDate: startDate,
                paginate: false,
                stopDate: stopDate,
                per_page: per_page,
                profile_type: profile_type
            };

            return this.httpClient
                .post(this._baseURL + 'device/power_profile', body, {
                    headers: this.headers
                })
                .pipe(
                    map((data: any) => {
                        return data.data.messages;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_28', true);
        }
    }

    getLastMessages(id, msgType) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = new HttpParams().set('msgType', msgType);
        return this.httpClient
            .post(this._baseURL + 'messages/last/' + id, body, {
                headers: this.headers
            })
            .pipe(
                map((data: any) => {
                    return data.data.message;
                })
            );
    }

    archiveMeteringDevice(device_id) {
        if (this._checkAccess.listAccess('delete', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.delete(this._baseURL + 'device/metering_device/remove_in_trash/' + device_id, {
                headers: this.headers
            });
        } else {
            this.errors.showSnackbar('msg_29', true);
        }
    }

    deleteMeteringDevice(device_id) {
        if (this._checkAccess.listAccess('delete', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.delete(this._baseURL + 'device/metering_device/remove_in_trash/' + device_id, {
                headers: this.headers
            });
        } else {
            this.errors.showSnackbar('msg_29', true);
        }
    }

    getDeletedMeteringDevices(device_group_id, search_string) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            device_group_id,
            is_deleted: true,
            per_page: 2500,
            search_string
        };
        return this.httpClient
            .post(this._baseURL + 'device/metering_devices', body, {
                headers: this.headers
            })
            .toPromise();
    }

    getDeletedGateways(search_string) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            is_deleted: true,
            per_page: 100,
            search_string
        };
        return this.httpClient.post(this._baseURL + 'device/gateways', body, { headers: this.headers }).toPromise();
    }

    getDeletedBaseStationDevices() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            is_deleted: true
        };
        return this.httpClient
            .post(this._baseURL + 'device/base_stations', body, {
                headers: this.headers
            })
            .toPromise();
    }

    deleteGateways(gateway_id) {
        if (this._checkAccess.listAccess('delete', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.delete(this._baseURL + 'device/gateway/remove_in_trash/' + gateway_id, {
                headers: this.headers
            });
        } else {
            this.errors.showSnackbar('msg_30', true);
        }
    }

    deleteGatewayFromBasket(gateway_id) {
        if (this._checkAccess.listAccess('delete', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.delete(this._baseURL + 'device/gateway/remove_from_trash/' + gateway_id, {
                headers: this.headers
            });
        } else {
            this.errors.showSnackbar('msg_31', true);
        }
    }

    restoreGatewayFromBasket(gateway_id) {
        if (this._checkAccess.listAccess('delete', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.delete(this._baseURL + 'device/gateway/restore_from_trash/' + gateway_id, {
                headers: this.headers
            });
        } else {
            this.errors.showSnackbar('msg_32', true);
        }
    }

    restoreDeviceFromBasket(device_id) {
        if (this._checkAccess.listAccess('delete', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.delete(this._baseURL + 'device/metering_device/restore_from_trash/' + device_id, {
                headers: this.headers
            });
        } else {
            this.errors.showSnackbar('msg_33', true);
        }
    }

    deleteDeviceFromBasket(device_id) {
        if (this._checkAccess.listAccess('delete', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.delete(this._baseURL + 'device/metering_device/remove_from_trash/' + device_id, {
                headers: this.headers
            });
        } else {
            this.errors.showSnackbar('msg_34', true);
        }
    }

    getGroupsMessage() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + 'device/messages/groups', { headers: this.headers }).pipe(
            map((data: any) => {
                return data.data.device_messages_groups;
            })
        );
    }

    gateways_compatible(compatible) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'device/gateways/compatible', compatible, {
                headers: this.headers
            })
            .toPromise();
    }

    getTaskDeviceId() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            device_id: 1
        };
        return this.httpClient.post(this._baseURL + 'device/get_task_by_device_id', body, { headers: this.headers });
    }

    getDevice(id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .get(this._baseURL + 'device/metering_device/' + id, {
                headers: this.headers
            })
            .pipe(
                map((response: any) => {
                    return response.data.metering_device;
                })
            );
    }

    allInterfaces() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/model/interfaces', '', {
            headers: this.headers
        });
    }

    getinterfaces(interfaces) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            connection_type: interfaces
        };
        return this.httpClient.post(this._baseURL + 'device/model/interfaces', body, { headers: this.headers });
    }

    getMeteringDevicesTypes() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'device/model/metering_device/types', '', {
                headers: this.headers
            })
            .pipe(
                map((response: any) => {
                    return response.data.metering_device_types;
                })
            );
    }

    getAttributesList() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get<any>(this._baseURL + 'device/attributes/list', {
            headers: this.headers
        });
    }

    getAttributes(id) {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            const body = {
                device_id: id
            };
            return this.httpClient
                .post(this._baseURL + 'device/attributes', body, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_35', true);
        }
    }

    addAttributes(attribute) {
        if (this._checkAccess.listAccess('update', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(this._baseURL + 'device/attribute/add', attribute, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_36', true);
        }
    }

    deleteAttribute(body) {
        if (this._checkAccess.listAccess('update', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(this._baseURL + 'device/attribute/delete', body, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_37', true);
        }
    }

    saveChangeAttribute(body) {
        if (this._checkAccess.listAccess('update', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .put(this._baseURL + 'device/attribute/update', body, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_38', true);
        }
    }

    getDeviceAddress(meteringPoint_device_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'device/address/get', { meteringPoint_device_id: meteringPoint_device_id }, { headers: this.headers })
            .toPromise();
    }

    getCollectionCardIndications(startDate, stopDate): Observable<any> {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(
            this._baseURL + 'device/collection_card_indications',
            {
                startDate: this.dateManipulations.dateUTC(startDate),
                stopDate: this.dateManipulations.dateUTC(stopDate)
            },
            {
                headers: this.headers
            }
        );
    }

    getCollectionCardPower(startDate, stopDate): Observable<any> {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(
            this._baseURL + 'device/collection_card_power',
            {
                startDate: this.dateManipulations.dateUTC(startDate),
                stopDate: this.dateManipulations.dateUTC(stopDate)
            },
            {
                headers: this.headers
            }
        );
    }

    getCollectionCardArchive(startDate, stopDate): Observable<any> {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(
            this._baseURL + 'device/collection_card_archive',
            {
                startDate: this.dateManipulations.dateUTC(startDate),
                stopDate: this.dateManipulations.dateUTC(stopDate)
            },
            {
                headers: this.headers
            }
        );
    }

    getCollectionCard(startDate, stopDate) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(
                this._baseURL + 'device/collection_card',
                {
                    startDate: this.dateManipulations.dateUTC(startDate),
                    stopDate: this.dateManipulations.dateUTC(stopDate)
                },
                {
                    headers: this.headers
                }
            )
            .toPromise();
    }

    sendingCustomCommands(body, title?, title_en?) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(
                this._baseURL + 'task/device/create',
                {
                    command_type: 'control',
                    title: title,
                    title_en: title_en || title,
                    device_ids: [body.id],
                    management: body.management
                },
                {
                    headers: this.headers
                }
            )
            .toPromise();
    }

    setControl(body, title?, title_en?) {
        if (this._checkAccess.listAccess('read', 4) || this._checkAccess.listAccess('update', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(
                    this._baseURL + 'task/device/create',
                    {
                        command_type: 'control',
                        title: title,
                        title_en: title_en || title,
                        device_ids: [body.id],
                        management: body.management
                    },
                    {
                        headers: this.headers
                    }
                )
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_39', true);
        }
    }

    //TODO: refactor all 'setControl' methods to this
    setDeviceControl(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'task/device/create', body, { headers: this.headers });
    }

    getControl(id) {
        if (this._checkAccess.listAccess('read', 4)) {
            const body = {
                id: id
            };
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.post(this._baseURL + 'device/control', body, { headers: this.headers }).toPromise();
        } else {
            this.errors.showSnackbar('msg_40', true);
        }
    }

    addOnDashboard(device_id) {
        const body = {
            device_id: device_id
        };
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'dashboard/add_device', body, {
                headers: this.headers
            })
            .toPromise();
    }

    deleteOnDashboard(device_id) {
        if (this._checkAccess.listAccess('read', 4) && this._checkAccess.listAccess('update', 5)) {
            const body = {
                device_id: device_id
            };
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(this._baseURL + 'dashboard/delete_device', body, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_41', true);
        }
    }

    editDevice(device, opros_timestamp_start?, autoloadParams?, pollParams?, poll_fields?, skip_poll_validation?, additional_fields?) {
        if (this._checkAccess.listAccess('update', 4) || this._checkAccess.listAccess('access_all', 4)) {
            let body;
            if (device.interface_id === this.deviceModelInterfacesEnum.virtual) {
                body = {
                    device_id: device.id,
                    name: device.name,
                    deviceTimezone: device.deviceTimezone,
                    inside_addr: device.inside_addr,
                    report_period_update: device.report_period_update,
                    concat_device: device.properties?.concat_device ? device.properties.concat_device : null,
                    virtual_fields_map: device?.virtual_fields_map ?? device.properties.virtual_fields_map ?? null,
                    virtual_fields_map_multiple:
                        device?.virtual_fields_map_multiple ?? device?.properties?.virtual_fields_map_multiple ?? null,
                    autoload_params: autoloadParams ? autoloadParams : undefined,
                    poll_params: pollParams ? pollParams : undefined
                };
                if (device.hasOwnProperty('deviceID')) {
                    body['deviceID'] = device.deviceID;
                }
            } else {
                if (device.device_group_id === 1) {
                    body = {
                        device_id: device.id,
                        name: device.name,
                        deviceTimezone: device.deviceTimezone,
                        inside_addr: device.inside_addr || '',
                        report_period_update: device.report_period_update,
                        autoload_params: autoloadParams ? autoloadParams : undefined,
                        poll_params: pollParams ? pollParams : undefined,
                        service_params: device.properties.service_params,
                        type_water_meter: device.properties['type_water_meter'],
                        starting_value: device.starting_value,
                        impulse_weight: device.impulse_weight
                    };
                } else if (device.device_group_id === 4) {
                    body = {
                        device_id: device.id,
                        name: device.name,
                        deviceTimezone: device.deviceTimezone,
                        inside_addr: device.inside_addr || '',
                        service_params: device.properties !== null ? device.properties.service_params : null,
                        autoload_params: autoloadParams ? autoloadParams : undefined,
                        poll_params: pollParams ? pollParams : undefined,
                        report_period_update: device.report_period_update,
                        transformation_ratio: device.transformation_ratio,
                        averaging_pp: device.properties?.averaging_pp ? device.properties.averaging_pp : 30
                    };
                    if (device.interface_id === this.deviceModelInterfacesEnum.Impulse) {
                        _.set(body, 'starting_value', device.starting_value);
                        _.set(body, 'impulse_weight', device.impulse_weight);
                        _.set(body, 'inside_addr', device.inside_addr || '');
                    }
                } else {
                    body = {
                        device_id: device.id,
                        name: device.name,
                        deviceTimezone: device.deviceTimezone,
                        inside_addr: device.inside_addr || '',
                        service_params: device.properties !== null ? device.properties.service_params : null,
                        autoload_params: autoloadParams ? autoloadParams : undefined,
                        poll_params: pollParams ? pollParams : undefined,
                        report_period_update: device.report_period_update,
                        starting_value: device.starting_value,
                        impulse_weight: device.impulse_weight
                    };
                }
                if (device.interface_id === this.deviceModelInterfacesEnum.LoRaWAN) {
                    _.set(body, 'base_station', device?.properties?.base_station?.replace(/\s/g, '') ?? null);
                }
                if (device.hasOwnProperty('gatewayID') && device.interface_id === this.deviceModelInterfacesEnum.CSD) {
                    body.deviceID = device.deviceID || '';
                    body.gatewayID = device.gatewayID || '';
                }
                // if (opros_timestamp_start !== null) {
                //     body['opros_timestamp_start'] = opros_timestamp_start;
                // }
                // if (poll_fields && Array.isArray(poll_fields)) {
                //     body['poll_fields'] = poll_fields;
                // }
                if (additional_fields && Array.isArray(additional_fields)) {
                    body['selected_data'] = additional_fields;
                }
                // if (skip_poll_validation) {
                //     body['skip_poll_validation'] = true;
                // }
                if (device.model_id === 551 || (device.model_id === 552 && device.properties.login && device.properties.password)) {
                    body['login'] = device.properties.login;
                    body['password'] = device.properties.password;
                }
            }
            body['warning_report_period'] = device?.warning_report_period || undefined;

            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(this._baseURL + 'device/metering_device/edit', body, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_42', true);
        }
    }

    saveDeviceSwitch(active, device_id) {
        if (this._checkAccess.listAccess('update', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.access_token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .put(this._baseURL + 'device/metering_device/switch/' + device_id, active, { headers: this.headers })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_43', true);
        }
    }

    deleteBaseStationOnTrash(id) {
        if (this._checkAccess.listAccess('delete', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .delete(this._baseURL + 'device/base_stations/remove_in_trash/' + id, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_44', true);
        }
    }

    restoreBaseStationFromBasket(id) {
        if (this._checkAccess.listAccess('delete', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.delete(this._baseURL + 'device/base_stations/restore_from_trash/' + id, {
                headers: this.headers
            });
        } else {
            this.errors.showSnackbar('msg_45', true);
        }
    }

    deleteBaseStationFromBasket(id) {
        if (this._checkAccess.listAccess('delete', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.delete(this._baseURL + 'device/base_stations/remove_from_trash/' + id, {
                headers: this.headers
            });
        } else {
            this.errors.showSnackbar('msg_46', true);
        }
    }

    tieUserDevice(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/tie_user', body, { headers: this.headers }).toPromise();
    }

    getSchedule(device_id) {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.post(this._baseURL + 'device/get_schedule', { device_id: device_id }, { headers: this.headers });
        } else {
            this.errors.showSnackbar('msg_47', true);
        }
    }

    deleteSchedule(device_id) {
        if (this._checkAccess.listAccess('update', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(this._baseURL + 'device/delete_schedule', { device_id: device_id }, { headers: this.headers })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_48', true);
        }
    }

    saveSchedule(params) {
        if (this._checkAccess.listAccess('update', 4) || this._checkAccess.listAccess('access_all', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(this._baseURL + 'device/save_schedule', params, {
                    headers: this.headers
                })
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_49', true);
        }
    }

    getTiesUsers(id) {
        if (this._checkAccess.listAccess('read', 4) && this._checkAccess.listAccess('read', 1) && this._checkAccess.listAccess('read', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            const body = {
                device_id: id
            };
            return this.httpClient
                .post(this._baseURL + 'device/get_ties_user', body, {
                    headers: this.headers
                })
                .pipe(
                    map((response: any) => {
                        return response.data.tie_users;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_50', true);
        }
    }

    // Посмотреть где используется и выпилить

    getMeteringDevicesByIds(devices_arr, per_page, page, deviceGroups?, isArchived = false, append_fields = []) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        devices_arr = devices_arr.filter((val) => val !== null && val !== undefined);
        return this.httpClient
            .post(
                this._baseURL + 'device/metering_devices',
                {
                    ids: devices_arr,
                    per_page: per_page,
                    page: page,
                    is_archived: isArchived,
                    paginate: false,
                    append_fields
                },
                { headers: this.headers }
            )
            .toPromise();
    }

    getDeviceFieldTitles() {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.get(this._baseURL + 'device/get_field_titles', {
                headers: this.headers
            });
        } else {
            this.errors.showSnackbar('msg_51', true);
        }
    }

    getAllAvailableAttributes() {
        if (this._checkAccess.listAccess('read', 4)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .get(this._baseURL + 'device/model/company/attributes', {
                    headers: this.headers
                })
                .pipe(
                    map((response: any) => {
                        return response.data.device_available_attributes;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_51', true);
        }
    }

    addBalanceGroup(request) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'balance/group/create', request, { headers: this.headers });
    }

    updateBalanceGroup(group_id, request) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.put(this._baseURL + 'balance/group/' + group_id, request, { headers: this.headers });
    }

    getBalanceGroups(paginate = true, page = 1, perPage = 10) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'balance/groups', { paginate, page, per_page: perPage }, { headers: this.headers })
            .pipe(
                map((response: any) => {
                    return response.data.balance_groups;
                })
            );
    }

    deleteBalanceGroup(group_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.delete(this._baseURL + 'balance/group/' + group_id, {
            headers: this.headers
        });
    }

    getOneBalanceGroup(group_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + 'balance/group/' + group_id, {
            headers: this.headers
        });
    }

    getAllMsgTypes() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + 'device/messages/types', {
            headers: this.headers
        });
    }

    saveUpdateBaseStation(changes: UntypedFormGroup, id) {
        const body = {
            id: id,
            name: changes.controls['name'].value,
            radius: changes.controls['radius'].value,
            desc: changes.controls['desc'].value
        };
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .put(this._baseURL + 'device/base_station/edit', body, {
                headers: this.headers
            })
            .pipe(
                map((response: any) => {
                    return response.data;
                })
            );
    }

    saveUpdateGateway(changes: UntypedFormGroup, id, device, deviceIDSettings = null) {
        const body = {
            id: id,
            name: changes.controls['name'].value,
            deviceTimezone: changes.controls['deviceTimezone'].value,
            desc: changes.controls['desc'].value,
            report_period_update: changes.controls['report_period_update'].value,
            warning_report_period: changes.controls['warning_report_period'].value,
            phone_number: changes.controls['phone_number'].value,
        };

        if (
            device.interface_id === this.deviceModelInterfacesEnum.LoRaWAN ||
            device.interface_id === this.deviceModelInterfacesEnum.LorawanChirpStack
        ) {
            _.set(
                body,
                'base_station',
                changes.controls['base_station'].value !== null && changes.controls['base_station'].value.replace(/\s/g, '') !== ''
                    ? changes.controls['base_station'].value
                    : null
            );
        }

        if (device.interface_id === this.deviceModelInterfacesEnum.ethernet_uspd) {
            if (changes.controls['password'].value.trim() !== '' && changes.controls['login'].value.trim() !== '') {
                _.set(body, 'login', changes.controls['login'].value);
                _.set(body, 'password', changes.controls['password'].value);
            } else {
                this.errors.showSnackbar('msg_144', true);
                return;
            }
        }

        if (!body.hasOwnProperty('base_station')) {
            body['base_station'] = null;
        }

        const reqBody = deviceIDSettings ? { ...body, ...deviceIDSettings } : body;

        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .put(this._baseURL + 'device/gateway/edit', reqBody, {
                headers: this.headers
            })
            .pipe(
                map((response: any) => {
                    return response.data;
                })
            );
    }

    getDeviceLogsById(device_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/get_log', { device_id: device_id }, { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    changeGatewayInDevice(deviceId, gatewayId, startingValue?, inside_addr?) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        let data;
        if (startingValue) {
            data = {
                device_id: deviceId,
                gateway_id: gatewayId,
                starting_value: startingValue,
                inside_addr: inside_addr
            };
        } else {
            data = { device_id: deviceId, gateway_id: gatewayId };
        }
        return this.httpClient
            .post(this._baseURL + 'device/gateway/replace', data, {
                headers: this.headers
            })
            .pipe(
                map((response: any) => {
                    return response.data;
                })
            );
    }

    getOpros(deviceId) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/opros', { device_id: deviceId }, { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data.device_polling;
            })
        );
    }

    clearTrash() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.delete(this._baseURL + 'device/metering_device/clear_trash', { headers: this.headers });
    }

    togglePollingActive(params) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.put(this._baseURL + 'device/opros/switch', params, { headers: this.headers });
    }

    getCompanyRejectedData(params) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'objects/accounting_point/company/rejected_data', params, {
            headers: this.headers
        });
    }

    getCompanyValidatedPowerProfile(params) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'objects/accounting_point/company/validated/power_profile', params, {
            headers: this.headers
        });
    }

    getJournalMessagesTypes() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/messages/types', { type_group_id: undefined }, { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data.device_messages_types;
            })
        );
    }

    getMessagesByTypes(deviceId, startDate, stopDate, msgTypes, previousPage, pageIndex, pageSize) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const reqBody = {
            device_id: deviceId,
            startDate,
            stopDate,
            paginate: true,
            msgType: msgTypes,
            page: pageIndex,
            last_page: previousPage
        };
        if (!_.isUndefined(pageSize) && !_.isNull(pageSize)) {
            reqBody['per_page'] = pageSize;
        }
        return this.httpClient.post(this._baseURL + 'device/system/messages/', { ...reqBody }, { headers: this.headers });
    }

    getSerialNumbersByDeviceIds(deviceIds) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(
                this._baseURL + 'devices/attribute/values',
                { deviceIds, attribute_name: 'device_serial_number', not_empty: true },
                { headers: this.headers }
            )
            .pipe(
                map((response: any) => {
                    return response.data.attribute_values;
                })
            );
    }

    onPollCreate(requestData) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const requestBody = {
            command_type: 'poll',
            device_ids: requestData.ids ? requestData.ids : [requestData.id],
            title: `Ручной опрос устройства`
        };
        return this.httpClient.post<any>(
            this._baseURL + 'task/device/create',
            { ...requestBody },
            {
                headers: this.headers
            }
        );
    }

    getTaskPoll(id = null, previousPage, pageIndex, pageSize, fieldSort, directionSort, status_id = null, pollGroup = false) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const requestBody = {
            page: pageIndex,
            last_page: previousPage,
            sort_field: fieldSort,
            sort: directionSort
        };
        if (status_id !== null) {
            requestBody['status_id'] = status_id;
        }
        if (id) {
            if (pollGroup) {
                requestBody['poll_group_id'] = id;
            } else {
                requestBody['device_id'] = id;
            }
        }

        if (!_.isUndefined(pageSize) && !_.isNull(pageSize)) {
            requestBody['per_page'] = pageSize;
        }

        return this.httpClient.post<any>(
            `${this._baseURL}task/device/list`,
            { ...requestBody },
            {
                headers: this.headers
            }
        );
    }

    cancelTask(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.put<any>(`${this._baseURL}task/device/cancel`, body, {
            headers: this.headers
        });
    }

    settingsClear(id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(`${this._baseURL}device/settings_clear/${id}`, id, {
            headers: this.headers
        });
    }

    getStatusList() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get<any>(`${this._baseURL}task/device/status/list`, { headers: this.headers });
    }

    getTaskDeviceLogs(id, previousPage, pageIndex, pageSize) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');

        const requestBody = {
            page: pageIndex,
            last_page: previousPage,
            task_id: id
        };

        if (!_.isUndefined(pageSize) && !_.isNull(pageSize)) {
            requestBody['per_page'] = pageSize;
        }

        return this.httpClient.post<any>(`${this._baseURL}task/device/logs`, { ...requestBody }, { headers: this.headers });
    }

    getLogTypeList() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get<any>(`${this._baseURL}task/device/log/types/list`, { headers: this.headers });
    }

    getLogResultCodeList() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get<any>(`${this._baseURL}task/device/log/result_code/list`, { headers: this.headers });
    }

    deleteMessage(reqData) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.delete(
            `${this._baseURL}device/metering_device/message`,
            {
                headers: this.headers,
                body: {...reqData}
            }
        );
    }

    onGetMeteringDevicesByIds(devices_arr) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post<any>(
            `${this._baseURL}device/metering_devices`,
            {
                ids: devices_arr,
                paginate: false,
                append_fields: ['attributes']
            },
            { headers: this.headers }
        );
    }

    getAccountingPoint(id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(`${this._baseURL}objects/accounting_point/${id}`, {
            headers: this.headers
        });
    }

    getTranslateFields(id: number | number[]) {
        const modelIdsArray = Array.isArray(id) ? id : [id];
        const body = {
            model_ids: modelIdsArray,
            append_attributes: ['device_fields_titles']
        };
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/model/metering_devices', body, { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data.metering_device_models[0];
            })
        );
    }

    getLightListMeteringDevices(options: any) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(
            `${this._baseURL}device/metering_devices`,
            {
                paginate: false,
                append_fields: ['attributes'],
                ...options
            },
            {
                headers: this.headers
            }
        );
    }

    generateUuid1() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'generate_uuid1', '', {
            headers: this.headers
        });
    }

    messageDirtyOne(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.put(this._baseURL + 'device/messages/dirty_one', body, {
            headers: this.headers
        });
    }

    gatewayMassReplace(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'device/gateway/mass_replace/', body, {
            headers: this.headers
        });
    }

    setConnectionParams(body: any, id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.patch(this._baseURL + `device/metering_devices/set_connection_params/${id}`, body, {
            headers: this.headers
        });
    }

    getListPorts() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + `admin/broker/list/ports`, {
            headers: this.headers
        });
    }

    getListConnectedDevices(filter_imei = '') {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(
            this._baseURL + `admin/broker/list/connected_gateways`,
            { filter_imei },
            {
                headers: this.headers
            }
        );
    }
}
