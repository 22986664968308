import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { ConfirmActionComponent } from '@shared/components/confirm-action/confirm-action.component';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { AlertWithInputComponent } from '@shared/components/alert-with-input/alert-with-input.component';
import { ValidatorFn } from '@angular/forms';
import { IdTitleSelector } from '@core/models/ui.models';
import { AlertWithSelectComponent } from '@shared/components/alert-with-select/alert-with-select.component';
import {AlertWithCheckboxComponent} from '@shared/components/alert-with-checkbox/alert-with-checkbox.component';

// TODO: move types to separate file if needed
export type Size = 's' | 'm' | 'l' | 'fit';

export type DialogData = {
    title?: string;
    content?: string;
    isError?: boolean; // красит контент в цвет ошибки
    confirmBtn?: string;
    cancelBtn?: string;
    placeholder?: string;
};

export type DialogDataWithInput = DialogData & {
    callBackFn?: (v: string) => void;
    validators?: ValidatorFn | ValidatorFn[];
};

export type DialogDataWithSelect = DialogDataWithInput & {
    items: IdTitleSelector[];
};

export type DialogDataWithCheckbox = DialogData & {
    checkbox?: string;
};
/**
 * @description расширенный сервис диалогов со стандартизированными по стилю и поведению модальными окнами
 */
@Injectable({
    providedIn: 'root'
})
export class DialogService {
    // TODO: подогнать размеры окон при небходимости или добавить промежуточные значения (xs xl и прочее)
    private dialogOptionsS: MatDialogConfig = {
        width: '300px',
        maxWidth: '90%',
        minWidth: '300px',
        maxHeight: '90%',
        panelClass: 'overflow-auto'
    };
    private dialogOptionsM: MatDialogConfig = {
        width: '500px',
        maxWidth: '90%',
        minWidth: '300px',
        maxHeight: '90%',
        panelClass: 'overflow-auto'
    };
    private dialogOptionsL: MatDialogConfig = {
        width: '90%',
        height: '90%',
        panelClass: 'overflow-auto'
    };
    private dialogOptionsFit: MatDialogConfig = {
        maxWidth: '90%',
        minWidth: '300px',
        width: 'fit-content',
        height: 'fit-content'
    };

    constructor(private readonly matDialog: MatDialog) {}

    /*
     * D - тип данных передаваемых в компонент / type for data
     * S - тип возвращаемого результата / type of returned result after closed
     * T - тип ссылки на компонент / type of component ref
     */
    open<D = any, S = any, T = any>(component: ComponentType<T>, item: D, size: Size = 'm', options?: MatDialogConfig<D>) {
        const sizeOptions = this.getSizeOptions(size);
        const config: MatDialogConfig<D> = options
            ? {
                  ...sizeOptions,
                  ...options,
                  data: item
              }
            : {
                  ...sizeOptions,
                  data: item
              };
        const ref: MatDialogRef<T, S> = this.matDialog.open(component, config);
        return ref.afterClosed();
    }

    alert(data?: DialogData) {
        return this.open<any, boolean, any>(AlertComponent, data);
    }

    alertWithInput(data?: DialogDataWithInput) {
        return this.open<any, string, any>(AlertWithInputComponent, data, 'm', { disableClose: true });
    }

    alertWithSelect(data: DialogDataWithSelect) {
        return this.open<any, IdTitleSelector, any>(AlertWithSelectComponent, data, 'm', { disableClose: true });
    }

    confirm(data: DialogData) {
        return this.open<any, boolean, any>(ConfirmActionComponent, data, 'm', { disableClose: true });
    }

    alertWithCheckbox(data: DialogData) {
        return this.open<any, boolean, any>(AlertWithCheckboxComponent, data, 'm', { disableClose: true });
    }

    closeAll() {
        this.matDialog.closeAll();
        return this.matDialog.afterAllClosed;
    }

    private getSizeOptions(size: Size) {
        switch (size) {
            case 's':
                return this.dialogOptionsS;
            case 'm':
                return this.dialogOptionsM;
            case 'l':
                return this.dialogOptionsL;
            case 'fit':
                return this.dialogOptionsFit;
        }
    }
}
