<h2 mat-dialog-title class="safe-text text-center">
    {{ title | translate }}
</h2>
<div mat-dialog-content *ngIf="content" class="safe-text text-edit ">
    <p
        class="text-center"
        [class.mat-error]="isError"
    >
        {{ content | translate }}
    </p>
</div>
<div mat-dialog-actions class="flex-evenly">
    <button
        mat-button
        mat-flat-button
        class="confirm-button safe-text"
        (click)="onClose()"
    >
        {{ cancelBtn | translate }}
    </button>
    <button
        mat-button
        mat-flat-button
        color="primary"
        class="confirm-button safe-text"
        (click)="onConfirm()"
    >
        {{ confirmBtn | translate }}
    </button>
</div>
